<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
      <h2>Здравейте{{this.$store.state.clientStr}}! Novacore Ви пожелава успешна 2021 година!</h2>

      <div class="social">
        <a class="social__icon icon--email" href="mailto:office@novacore.bg">
          <span></span>
          office@novacore.bg
        </a>
        <a class="social__icon icon--phone" href="tel:+359886577961">
          <span></span>
          +359 886 577 961
        </a>
      </div>
    </div>
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: mapState([
    'client',
    'clientStr',
  ]),
  created() {
    this.$store.dispatch('getClient');
  },
};
</script>

<style lang="scss">
body {
  margin: 0 !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.icon--email span {
  background-image: url('assets/svg/nvcr-email.svg');
}

.icon--phone span {
  background-image: url('assets/svg/nvcr-phone.svg');
  background-position: right;
}
</style>
