import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    fortunes: [],
    challenges: [],
    client: '',
    clientStr: '',
  },
  mutations: {
    SET_FORTUNES(state, data) {
      state.fortunes = data.fortune;
      state.challenges = data.challenge;
    },
    SET_CLIENT(state, client) {
      if (client) {
        state.clientStr = `, ${client}`;
        state.client = client;
        console.log(state.client);
      }
    },
  },
  actions: {
    loadSingleFortune({ commit, state }, payload) {
      let URL = 'https://dev.novacore.bg/xmas/public/api/v1/';
      // let URL = 'https://diy.borsolutions.com/bor-christmas-2020/backend/public/api/v1/fortune';
      if (state.client) {
        // console.log(`Koceto: ${state.client}`);
        URL = `${URL}${payload.fortuneType}/${state.client}`;
      }

      axios
        .get(URL)
        .then((r) => r.data)
        .then((fortunes) => {
          console.log(fortunes.data);
          commit('SET_FORTUNES', fortunes.data);
        });
    },
    getClient({ commit }) {
      const uri = window.location.search.substring(1);
      const client = new URLSearchParams(uri).get('client');

      commit('SET_CLIENT', client);
      console.log(client);
    },
  },
  modules: {
  },
});
