<template>
  <div class="home">
    <Fortunes />
    <Challenges />
  </div>
</template>

<script>
// @ is an alias to /src
import Fortunes from '../components/Fortunes.vue';
import Challenges from '../components/Challenges.vue';

export default {
  name: 'Home',
  components: {
    Fortunes,
    Challenges,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prata&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Alegreya&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

</style>
