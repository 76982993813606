<template>
  <div class="fortune m-wrapper">
    <transition name="slide-fade" mode="out-in">
      <div class="fortune__body m-content">
        <h2>Късметче</h2>
        <p
          class="fortune__text m-content__message m-content__message--left"
          :key="this.$store.state.fortunes.id">
            {{this.$store.state.fortunes}}
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Fortunes',
  props: {
    // msg: String,
    // buttonText: String,
  },
  mounted() {
    const payload = { fortuneType: 'fortune' };
    this.$store.dispatch('loadSingleFortune', payload);
  },
  computed: {
    ...mapState(['fortunes', 'client']),
  },
  methods: {
    getFortune() {
      // this.$store.dispatch('loadSingleFortune');
      // Add to history
      // this.fortunesHistory.push(this.$store.state.fortunes.text);
      // console.log('Koceto');
      // https://dribbble.com/shots/9198425-Happy-New-Year-from-bazen-agency
      // https://dribbble.com/shots/12551434-Quotable-font-Brave-Babe
      // https://dribbble.com/shots/10842266-Quotes-App-UI-Design
      // https://dribbble.com/shots/6254252-posi-vibes
      // https://dribbble.com/shots/8381351-Word-series-4-99
      // https://paletton.com/#uid=75C0u0kjSpI9NEOf6uynJlFsCgL
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.fortune {
  background-image: url('../assets/background-fortunes.jpg');
}
</style>
